@media (max-width: 700px) {
    #chat,
    #subtitles {
        width: calc(100% - 20px);
    }

    .header-options button svg {
        height: 18px;
    }

    .header-title {
        font-size: 1em;
    }

    .hidden-mobile {
        display: none;
    }
}
