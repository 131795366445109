header {
    width: calc(100% - 2em);
    height: 50px;
    display: flex;
    justify-content: space-between;
    position: relative;
    background: linear-gradient(90deg, white 10%, var(--main-primary) 100%);
    padding: 0 1em;
}

.header-left-section,
.header-center-section,
.header-right-section {
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--header-bg-primary);
}

.info-user {
    height: 90%;
    position: relative;
    display: flex;
    align-items: center;
}

.info-user img {
    height: calc(100% - 5px);
}

.info-user span {
    color: var(--header-bg-primary);
    font-size: 1.2em;
    margin-left: 0.3em;
}

.logout-btn,
.participants-btn,
.share-btn {
    color: var(--header-bg-primary);
    font-size: 1.2em;
    margin-right: 10px;
    cursor: pointer;
}

.settings-user {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.settings-user .fa-user-circle {
    color: #ccc;
    font-size: 1.3em;
}

.settings-user .profile-name {
    color: var(--header-bg-primary);
    font-size: 1.2em;
}

.logout-btn:hover,
.participants-btn:hover,
.share-btn:hover {
    color: #999;
}

.direct-buttons button {
    margin: 0 3px;
    width: 30px;
    height: 30px;
}

.participants-label {
    font-size: 0.8em;
}
